import { Component } from '@angular/core';
import { Order, SubOrderLamella } from '../interfaces/order';
import { Options } from '../interfaces/options';
import { Price } from '../interfaces/price';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NumberPipe } from '../pipes/number.pipe';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatMiniFabButton } from '@angular/material/button';

@Component({
  selector: 'app-create-order-lamella',
  standalone: true,
  imports: [FormsModule, DatePipe, NumberPipe, MatMiniFabButton],
  templateUrl: './create-order-lamella.component.html',
  styleUrl: './create-order-lamella.component.scss'
})
export class CreateOrderLamellaComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
  ){
    apiService.getOptions().subscribe((data: Options) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        this.loadingOpt = false;
        // this.errorMessage = '';
        this.options = data;
        this.styleDep = data.lamellaDecor.styleDep;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    apiService.getPrice().subscribe((data: Price) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        this.loadingPrice = false;
        // this.errorMessage = '';
        this.price = data;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    authService.getCookie().subscribe((user)=>{
      let role = user.role;
      let person = user.person;
      this.phone = user.phone;
      this.name = user.name;
      if(user.bitrixWorkID){
        this.bitrixWorkID = user.bitrixWorkID;
      }
      if(user.parentID){
        this.parentID = user.parentID;
      }
      if (
        role === 'Менеджер' ||
        role === 'Админ' ||
        role === 'Владелец')
      {
        this.statusCheck = true;
        this.roleCheck = true;
      }
      if (person !== 'Розничный покупатель'){
        this.statusCheck = true;
      }
      this.person = person;

      const order_check = localStorage.getItem('order');
      if(order_check){
        const ORDER_COPIED = JSON.parse(order_check) as Order;
        if(ORDER_COPIED.commentsForCompany){
          this.messageForCompany = ORDER_COPIED.commentsForCompany;
        }
        if(ORDER_COPIED.commentsForClient){
          this.messageForClient = ORDER_COPIED.commentsForClient;
        }
        if(ORDER_COPIED.orderLamella){
          this.doneOrders = ORDER_COPIED.orderLamella;
        }
        if(ORDER_COPIED.createdResponsible){
          this.phoneForWho = ORDER_COPIED.phone;
        }
        if(ORDER_COPIED.phoneChildren && !this.parentID){
          this.childrenCheck = true;
          if(ORDER_COPIED.phoneChildren){
            this.parentID = ORDER_COPIED.phoneChildren;
          }
        }
        if(ORDER_COPIED.orderLamella == undefined || ORDER_COPIED.orderLamella.length === 0){
          this.errorMessage = 'Заказ пуст или сломан.';
          return;
        }
        for(const order of ORDER_COPIED.orderLamella){
          this.totalAmount += order.amount;
          if(order.priceCalc){
            this.totalPrice += order.priceCalc;
          }
          if(order.priceMarkup){
            this.totalPriceMarkup += order.priceMarkup;
          }
        }
        localStorage.removeItem('order');
        this.errorMessage = 'Заказ был успешно скопирован.';
      }
    });
    //Copy order?

    this.authService.setOrderGuardCheck();
  }

  MAP_TYPE: { [key: string]: string } = {
    '': '',
    '3side': '3х сторон',
    '4side': '4х сторон',
  };

  MAP_STANDART: { [key: string]: string } = {
    '': '',
    'std': '<= 2790мм',
    'nstd': '>= 2791мм',
  };

  MAP_PLUG: { [key: string]: string } = {
    '': '',
    '0side': 'Нет',
    '1side': '1 ст',
    '2side': '2 ст',
  };

  TEMP_PRICE: { [key: string]: number } = {
    '1': 4250,
    '2': 4700,
    '3': 5150,
    '4': 5350,
    '5': 6250,
  };

  phone: string = '';
  parentID: string = '';
  bitrixWorkID: string = '';
  phoneForWho: string = '';
  name: string = '';
  person: string = '';

  options!: Options;
  styleDep!: {
    entries: {
      [entry: string]: string;
    };
  };
  price!: Price;

  errorMessage: string = '';
  addedOrder: string = '';

  loadingOpt: boolean = true;
  loadingPrice: boolean = true;
  statusCheck: boolean = false;
  markupCheck: boolean = false;
  roleCheck: boolean = false;

  doneOrders: SubOrderLamella[] = [];

  // textureArr: string[] = ['Вертикальная', 'Горизонтальная'];

  typeL: string = '';
  standart: string = '';

  style: string = '';
  decor: string = '';
  plug: string = '';

  incogintoMode: boolean = false;

  height: number = 0;
  width: number = 0;
  length: number = 0;

  amount: number = 0;
  totalAmount: number = 0;
  area: number = 0;
  
  priceCalc: number = 0;
  priceMarkup: number = 0;
  pricePerMeter: number = 0;
  paymentPlanForm: string = '';

  // @ts-ignore
  paymentDate:Date;

  markup: number = 0;
  markupConst: number = 0;

  totalPrice: number = 0;
  totalPriceMarkup: number = 0;
  
  messageForClient: string = '';
  messageForCompany: string = '';

  optionsCount = 0;

  childrenCheck: boolean = false;
  
  copy(index: number){
    const order = this.doneOrders[index];
    this.resetOptions(1);
    
    this.typeL = order.typeL;
    this.standart = order.standart;
    this.decor = order.decor;
    this.plug = order.plug;
    this.style = this.options.lamellaDecor.styleDep.entries[this.decor];

    this.height = order.height;
    this.width = order.width;
    this.length = order.length;
    this.amount = order.amount;
    this.resetOptions(20);
  }

  createOrder(statusStr: string){
    if(this.totalPrice === 0){
      this.errorMessage = "Заказ пуст";
      return;
    }
    if(statusStr !== 'Сохранён' && !this.paymentPlanForm){
      this.errorMessage = "Не указана форма оплаты";
      return;
    }
    if(statusStr !== 'Сохранён' && !this.paymentDate){
      this.errorMessage = "Не указана планируемая дата оплаты";
      return;
    }
    if(this.phoneForWho){
      if(!this.validatePhone(this.phoneForWho)){
        this.errorMessage = 'Формат телефона должен быть 89821234567';
        return;
      }
      this.apiService.getUserByPhone(this.phoneForWho).subscribe((data)=>{
        if(!data){
          this.errorMessage = 'Пользователь не существует';
          return;
        }else{
          this.confirmAndCreateOrder(statusStr);
        }
      });
    }else{
      this.confirmAndCreateOrder(statusStr);
    }   
  }

  private confirmAndCreateOrder(statusStr: string){
    if(statusStr == 'Сохранён'){
      if(!confirm("Сохранить заказ?")){
        return;
      }
    }else{
      if(!confirm("После отправления заказа на оформление менеджеру вам будет направлен счёт с индивидуальной скидкой. Оптовая сумма будет отображена в журнале заказов.\nПОСЛЕ ОПЛАТЫ СЧЁТА, КОРРЕКТИРОВКА ИЛИ ИЗМЕНЕНИЯ ЗАКАЗА НЕВОЗМОЖНЫ")){
        return;
      }
    }
    let orderObj: Order = {
      orderLamella: this.doneOrders,
      commentsForClient: this.messageForClient,
      commentsForCompany: this.messageForCompany,
      paymentPlanForm: this.paymentPlanForm,
      paymentPlanDate: this.paymentDate,
      phone: this.phone,
      person: this.person,
      name: this.name,
      phoneChildren: this.parentID,
      totalPrice: Math.ceil(this.totalPrice),
      totalPriceCalc: Math.ceil(this.totalPrice),
      totalPriceMarkup: Math.ceil(this.totalPriceMarkup),
      kind: 'lamella',
      status: statusStr,
      ...(this.phoneForWho ? {createdResponsible: this.phoneForWho} : {}) //На этом этапе поменяны местами логика, на бэке норм встаёт
    };
    if(this.childrenCheck){
      orderObj['childrenCheck'] = true;
    }
    this.loadingOpt = true;
    this.apiService.createOrder(orderObj).subscribe((data: any) => {
      if ('message' in data) {
        if (
          !data.message.includes('создан') &&
          !data.message.includes('sent')
        ) {
          this.errorMessage = JSON.stringify(data.message);
          this.loadingOpt = false;
          return;
        }
      }
      this.authService.setCookieCreatedOrder(data);
      this.authService.removeOrderGuardCheck();
      this.router.navigate(['list']);
    });
  }

  addOrder(){
    this.errorMessage = '';
    this.addedOrder = '';

    if(!this.canCalcPrice()){
      return;
    }

    this.calcPrice();

    let sub: SubOrderLamella = {
      typeL: this.typeL,
      standart: this.standart,
      plug: this.plug,
      decor: this.decor,
      height: this.height,
      length: this.length,
      width: this.width,
      amount: this.amount,
      price: this.pricePerMeter,
      priceCalc: this.priceCalc,
      priceMarkup: this.priceMarkup,
    };
    
    this.totalPrice += this.priceCalc;
    this.totalPriceMarkup += this.priceMarkup;
    this.totalAmount += +this.amount;
    this.addedOrder = 'Позиция добавлена';
    this.doneOrders.push(sub);
  }

  canCalcPrice(): boolean{
    if(!this.typeL){
      this.errorMessage = 'Не выбран тип рейки';
      return false;
    }
    if(!this.standart){
      this.errorMessage = 'Не указана длина реек';
      return false;    
    }
    if(!this.plug){
      this.errorMessage = 'Не указаны заглушки';
      return false;    
    }
    if(!this.decor){
      this.errorMessage = 'Не указан декор';
      return false;    
    }

    if(!this.height || this.height <= 0){
      this.errorMessage = 'Не указана высота';
      return false;    
    }
    if(!this.width || this.width <= 0){
      this.errorMessage = 'Не указана ширина';
      return false;    
    }
    if(!this.length || this.length <= 0){
      this.errorMessage = 'Не указана длина';
      return false;    
    }
    if(!this.amount || this.amount <= 0){
      this.errorMessage = 'Не указано количество';
      return false;    
    }

    switch(this.typeL){
      case "3side":
        if(this.height < 30){
          this.errorMessage = 'Высота меньше допустимой (30мм)';
          return false;    
        }
        if(this.width < 35){
          this.errorMessage = 'Ширина меньше допустимой (35мм)';
          return false;    
        }
        break;
      case "4side":
        if(this.height < 50){
          this.errorMessage = 'Высота меньше допустимой (50мм)';
          return false;    
        }
        if(this.width < 50){
          this.errorMessage = 'Ширина меньше допустимой (50мм)';
          return false;    
        }
        break;
      default:
        this.errorMessage = "Ошибка при выборе типа реек";
        return false;
    }
    const maxLen = this.typeL === '3side' ? 2790 : 3500;
    // if(length < скольки?)
    if(this.length > parseInt(this.getMaxLength())){
        this.errorMessage = "Длина превышает допустимый размер";
        return false;
    }
    if(this.length < 500){
      this.errorMessage = "Минимальная длина - 500 мм";
      return false;
    }
    
    switch(this.typeL){
      case "3side":
        if(this.height*2+this.height+12 > 1200){
          this.errorMessage = "Превышен максимальный размер листа - 1200мм";
          return false;
        }
        break;
      case "4side":
        if(this.height*2+this.height*2 > 1200){
          this.errorMessage = "Превышен максимальный размер листа - 1200мм";
          return false;
        }
        break;
      default:
        this.errorMessage = "Ошибка при выборе типа реек";
        return false;
    }

    return true;
  }

  calcArea() {
    const mult = this.typeL === '3side' ? 1 : 2;
    this.area = ((+this.height*2) + (+this.width*mult)) * (+this.length)  / 1000000;
  }

  calcPrice() {
    this.priceCalc = 0;
    if(!this.canCalcPrice()){
      return;
    }

    this.addedOrder = '';
    this.errorMessage = '';

    this.calcArea();
    
    this.pricePerMeter = this.TEMP_PRICE[this.options.lamellaDecor.groupDep.entries[this.decor]];

    this.priceCalc = Math.ceil(this.pricePerMeter * this.area);

    let markupLocal = 1 + (this.markupConst/100);
    this.priceMarkup =  Math.ceil(this.priceCalc * markupLocal);
  
  }

  doMarkup(){
    this.markupConst = this.markup;
    let markupLocal = 1 + (this.markupConst/100);
    this.totalPriceMarkup = 0;
    for (const subOrder of this.doneOrders) {
      if(subOrder.priceCalc){
        subOrder.priceMarkup = Math.ceil(subOrder.priceCalc * markupLocal);
        this.totalPriceMarkup += subOrder.priceMarkup;
      }
    }
  }

  removeSuborder(i: number){
    this.addedOrder = '';
    this.errorMessage = '';
    
    this.totalAmount -= +this.doneOrders[i].amount;

    //@ts-ignore
    this.totalPrice -= +this.doneOrders[i].priceCalc;    
    //@ts-ignore
    this.totalPriceMarkup -= +this.doneOrders[i].priceMarkup;

    this.doneOrders.splice(i, 1);
  }

  resetOptions(num: number) {
    this.addedOrder = '';
    this.errorMessage = '';
    this.optionsCount = num;
    if(num < 2){
      this.standart = '';
    }
    if(num < 3){
      this.plug = '';
    }
    if (num == 3){
      if (this.plug === '0side' && this.standart !== 'nstd'){
        this.length = 2790;
        }else{
        this.length = 0;
      }
    }
    if(num < 4){
      this.style = '';
    }
    if(num < 5){
      this.decor = '';
    }
  }

  getMaxLength(): string{
    const len = this.standart === 'std' ? 2790 : 3500;
    switch (this.plug){
      case "0side":
        return len.toString();
      case "1side":
        return (len-(+this.height)).toString();
      case "2side":
        return (len-(+this.height)*2).toString();
      default:
        return "Ошибка";
    }

  }

  ceil(num: number){
    return Math.ceil(num);
  }
  resetMessages(){
    this.addedOrder = '';
    this.errorMessage = '';
  }

  private validatePhone(phone: string) {
    const regex = /^(8)[0-9]{3}[0-9]{7}$/;
    if(regex.test(phone)){
      return true;
    }
    else{
      return false;
    }
  }
}
